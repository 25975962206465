<template>
    <v-dialog :value="value" persistent width="50%">
        <v-card>
            <v-toolbar flat dense>
                <v-layout d-flex justify-end>
                    <v-btn icon @click="close">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-layout>
            </v-toolbar>

            <div class="pa-3">
              <v-form ref="Anfrage" v-model="valid">
                <v-text-field v-model="Artikelnummer" :label="$t('anfrage.artikelnummer')"></v-text-field>
                <v-text-field v-model="Hersteller" :label="$t('anfrage.hersteller')"></v-text-field>
                <v-text-field v-model="Beschreibung" :label="$t('anfrage.beschreibung')"></v-text-field>
                <v-text-field v-model="Hinweis" :label="$t('anfrage.hinweis')"></v-text-field>
                <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="addToCart"
                >{{$t('cart.addrequest')}}</v-btn>
              </v-form>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "OrylArtikelAnfrage",
        props: {
          value: {
            type: Boolean,
            default: false,
          }
        },
        data: function () {
            return {
              Artikelnummer: '',
              Hersteller: '',
              Beschreibung: '',
              Hinweis: '',
              valid: true,
            }
        },
        computed: {
        },
        methods: {
          close: function () {
            this.item = null;
            this.loaded = false;
            this.$emit('input', false);
          },
          addToCart: function () {
            let item = {
              "ids": {
                "tecdocId": this.Artikelnummer,
                "webiscoId": null,
                "genericId": 0
              },
              "data": {
                "no": "0",
                "name": this.Beschreibung,
                "extratext": this.Hinweis
              },
              "brand": {
                "id": 0,
                "name": this.Hersteller
              },
              "attributes": [],
              "OEMInfo": [],
              "prices": [],
              "deposit": 0,
              "salesUnit": 1,
              "stocks": {
                "internal": 0,
                "external": 0
              },
              "img": "",
              "primary": true
            };
            this.$store.dispatch('CartStore/addItem', item);
            this.close();
          }
        },
        watch: {
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
