<template>
    <v-card tile :loading="loading">
        <form @submit.prevent="searchRequest()" class="pt-3">
            <v-text-field outlined hide-details="auto" :label="$t('shop.search')" append-icon="mdi-magnify" @click:append="searchRequest()" :rules="rules" v-model="searchInput" class="ma-3"/>
            <v-tabs fixed-tabs>
                <v-tab @click="searchRequest('full')" value="full">
                    <v-img src="@/assets/tecdoc_inside.png" max-width="7.5em" contain/>
                </v-tab>
                <v-tab @click="searchRequest('intern')" value="intern">
                    <v-img src="@/assets/oryl_autoteile_logo.jpg" max-width="7.5em" contain/>
                </v-tab>
            </v-tabs>
        </form>
    </v-card>
</template>

<script>
    export default {
        name: "OrylSearchBar",
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        search: '',
                        type: 'full',
                    }
                }
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                searchInput: '',
                rules: [
                    value => (value && value.length >= 4) || this.$t('shop.error.minsearch')
                ],
                searchTypeRadio: 'full',

            }
        },
        computed: {
        },
        methods: {
            searchRequest: function (typ) {
                if (typ) {
                  this.searchTypeRadio = typ;
                }
                this.$emit('input', {search: this.searchInput, type: this.searchTypeRadio});
            },
            reset: function () {
                this.searchInput = '';
                this.searchTypeRadio = '';
            }
        }
    }
</script>

<style scoped>

</style>
