<template>
    <v-card tile>
        <v-toolbar color="primary" dense dark flat>
            <div>{{$t('shop.filtercategories')}}</div>
            <v-spacer/>
            <v-btn icon @click="open = !open">
                <v-icon v-show="open">
                    mdi-minus
                </v-icon>
                <v-icon v-show="!open">
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </v-toolbar>
        <div class="px-3">
            <v-expand-transition>
                <v-layout d-flex column v-if="open">
                    <v-text-field flat v-model="search" :label="$t('shop.search')" clearable/>
                    <v-treeview :items="items" :search="search" open-on-click dense hoverable activatable @update:active="select($event[0])" style="cursor: pointer;"/>
                </v-layout>
            </v-expand-transition>
        </div>
    </v-card>
</template>

<script>
    export default {
        name: "OrylShopCategories",
        data: function () {
            return {
                open: true,
                search: ''
            }
        },
        computed: {
            items: function () {
                return this.$store.state.ShopStore.categories;
            }
        },
        methods: {
            select: function (value) {
                this.$vuetify.goTo(0);
                this.$emit('input', value);
            }
        }
    }
</script>

<style scoped>

</style>
