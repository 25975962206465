<template>
  <v-card tile class="mb-3">
    <v-toolbar color="primary" dense dark flat>
      <div>{{$t('shop.filter.search.name')}}</div>
      <v-spacer/>
      <v-btn icon @click="open = !open">
        <v-icon v-show="open">
          mdi-minus
        </v-icon>
        <v-icon v-show="!open">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <v-layout d-flex column v-if="open" class="pa-3">
        <v-autocomplete clearable :label="$t('shop.filter.search.brand')" v-model="settings.brand" :items="brands" dense/>
        <v-select clearable v-model="settings.product" :items="products" :disabled="!products.length" :label="$t('shop.filter.search.product')" dense @change="settings.attributes = []"/>
        <v-select clearable v-model="settings.attributes[name]" v-for="(values, name) in criteria" :key="name" :items="values" :label="name" dense @change="changeAttributes"/>
        <v-btn color="primary" @click="reset" block>
          <v-icon>
            mdi-undo-variant
          </v-icon>
        </v-btn>
      </v-layout>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "OrylShopFilter",
  props: ['value'],
  data: function () {
    return {
      open: true,
      settings: {
        attributes: []
      }
    }
  },
  computed: {
    results: function () {
      let results = this.$store.state.ShopStore.results;
      if (this.settings.product) {
        results = results.filter(function (result) {
          return result.ids.genericId === this.settings.product;
        }.bind(this));
      }
      return results;
    },
    brands: function () {
      return this.$store.state.ShopStore.brands;
    },
    products: function () {
      return this.$store.state.ShopStore.products;
    },
    criteria: function () {
      /*const unique = this.products.map(item => item.value)
          .filter((value, index, self) => self.indexOf(value) === index);
      if (unique.length === 1) {
        this.setProduct(this.products[0].value);
      }*/
      if (this.products.length && !this.settings.product) {
        return [];
      }
      let criteria = {};

      this.results.forEach(function (item) {
        item.attributes.forEach(function (attribute) {
          if (criteria[attribute.name] === undefined) {
            criteria[attribute.name] = [];
          }

          let exists = criteria[attribute.name].some(function (test) {
            return test === attribute.value;
          });

          if (exists) {
            return;
          }

          criteria[attribute.name].push(attribute.value);
        })
      })

      for (var prop in criteria) {
        if (criteria[prop].length === 1 && !this.settings.attributes[prop]) {
          delete criteria[prop];
        }
      }

      return criteria;
    }
  },
  methods: {
    setProduct: function (val) {
      if (this.settings.product !== val) {
        this.settings.product = val;
        this.checkChanges();
      }
    },
    changeAttributes: function () {
      let settings = {...this.settings};
      settings.attributes = [];

      for (let [key, value] of Object.entries(this.settings.attributes)) {
        settings.attributes.push({
          name: key,
          value: value
        })
      }

      this.$emit('input', settings);
    },
    reset: function () {
      this.settings = {
        attributes: {}
      };
    },
    checkChanges: function () {
      /*const unique = this.products.map(item => item.value)
          .filter((value, index, self) => self.indexOf(value) === index);
      if (unique.length === 1) {
        this.settings.product = this.products[0].value;
      }*/
      let settings = {...this.settings};
      settings.attributes = [];

      for (let [key, value] of Object.entries(this.settings.attributes)) {
        settings.attributes.push({
          name: key,
          value: value
        })
      }

      this.$emit('input', settings);
    },
  },
  mounted() {
    this.$store.dispatch('ShopStore/getBrands');
  },
  watch: {
    settings: {
      handler() {
        this.checkChanges();
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
