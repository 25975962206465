<template>
    <v-card>
        <v-toolbar color="primary" dense dark flat>
            <div>{{$t('cardetails.title')}}</div>
            <v-spacer/>
            <v-btn icon @click="open = !open">
                <v-icon v-show="open">
                    mdi-minus
                </v-icon>
                <v-icon v-show="!open">
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </v-toolbar>
        <v-simple-table v-if="open && details.length !== 0">
            <tbody>
            <tr v-for="(value, key) in details" :key="key">
                <th>{{$t('cardetails.' + key)}}</th>
                <td>{{value}}</td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
    export default {
        name: "OrylCarDetails",
        props: ['car'],
        data: function () {
            return {
                open: true,
                details: []
            }
        },
        mounted() {
            if (!this.car) {
                return;
            }

            this.$store.dispatch('ShopStore/getCarDetails', this.car)
                .then((response) => {
                    this.details = response;
                });
        }
    }
</script>

<style scoped>

</style>
