<template>
    <v-dialog :value="value" persistent width="70%">
        <v-card>
            <v-toolbar flat dense>
                <v-layout d-flex justify-end>
                    <v-btn icon @click="close">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-layout>
            </v-toolbar>

            <div class="pa-3">
                <v-layout d-flex justify-center v-if="!loadedCart.length && !item && !loaded">
                    <v-progress-circular color="primary" size="100" indeterminate class="my-10"/>
                </v-layout>
                <v-layout d-flex justify-center v-else-if="!loadedCart.length && !item && loaded">
                  <div>Es wird kein passende Artikel gefunden</div>
                </v-layout>
                <v-data-table v-else :items="list" :headers="headers"
                              @click:row="select"
                              :footer-props="{
                  'items-per-page-options': [15, 25, 50, 100]
                }"
                :items-per-page="25">
                </v-data-table>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: "OrylShopLoader",
        props: {
            value: {
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {
                item: null,
                loaded: false,
                list: []
            }
        },
        computed: {
          headers () {
            return [
              {
                text: 'Artikelnummer',
                align: 'start',
                sortable: true,
                value: 'Artikelnummer',
              },
              { text: 'Hersteller', value: 'Hersteller' },
              { text: 'Beschreibung', value: 'Beschreibung' },
              { text: 'Hinweis', value: 'Hinweis' },
            ]
          },
            loadedCart: function () {
              if (this.$store.state.ShopStore.popup.length>0 && this.item == null) {
                this.process();
              }
                return this.$store.state.ShopStore.popup;
            },
        },
        methods: {
            select: function (index) {
                this.$store.state.ShopStore.Step2 = {
                  search: index.Artikelnummer,
                  type: 'intern',
                  brand: index.Brand
                };
                this.item = null;
                this.loaded = false;
                this.close();
            },
            process: function () {
                this.$store.dispatch('ShopStore/popLoadedCart')
                .then((response) => {
                    if (!Object.keys(response).length) {
                        this.close();
                        return;
                    }
                    this.item = response;
                    this.ItemsToList();
                });
            },
              close: function () {
                  this.$store.state.ShopStore.popup = [];
                  this.loaded = false;
                  this.$emit('input', false);

            },
            ItemsToList: function () {
              let list = [];
              this.item.forEach(function (artikel) {
                list.push(
                    {
                      'Artikelnummer': artikel.data.no,
                      'Hersteller': artikel.brand.name,
                      'Brand': artikel.brand.id,
                      'Beschreibung': artikel.data.name,
                      'Hinweis': artikel.data.extratext
                    }
                );
              });
              this.list = list;
            }
        },
        watch: {
            loadedCart: {
                handler() {
                    if (this.loadedCart.length > 0 && !this.loaded) {
                        this.loaded = true;
                        this.process();
                    } else {
                      this.loaded = true;
                      this.item = null;
                    }
                }
            }
        },
        mounted() {
            this.item = null;
        }
    }
</script>

<style scoped>

</style>
