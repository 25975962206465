<template>
    <oryl-container>
        <v-row>
            <v-col cols="12" lg="3" class="mb-0 pb-0">
                <oryl-car-filter v-model="vehicle"/>
                <oryl-shop-categories v-if="categories" v-model="category" @input="searchCategoryRequest"/>
            </v-col>
            <v-col cols="12" lg="6">
                <oryl-search-bar ref="searchbar" :value="search" @input="searchRequest($event)" :loading="loading"/>
              <v-row>
                <v-col cols="8">
                  <v-btn color="success" class="mr-4" @click="makeRequest()">{{$t('shop.makerequest')}}</v-btn>
                </v-col>
                <v-col cols="4">
                  <v-switch
                      v-if="search.type==='intern'"
                      v-model="sucheTyp"
                      inset
                      :label="`${$t('shop.searchTyp')}: ${sucheTypC}`"
                  ></v-switch>
                </v-col>
              </v-row>
                <v-row v-show="results.length">
                    <v-col cols="12">
                        <v-pagination :length="pages" v-model="pagination"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" v-show="loading">
                        <v-card tile v-for="i in 3" :key="i" class="mb-2">
                            <v-skeleton-loader type="list-item-avatar-three-line"/>
                        </v-card>
                    </v-col>
                    <v-col cols="12" v-for="(item, key) in results" :key="key + Math.random()" class="my-0 mb-2 py-0">
                        <oryl-shop-result :item="item" @select="select(item)"/>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center headline text--secondary" v-if="!loading && !searched">{{$t('shop.entersearch')}}</v-col>
                    <v-col cols="12" class="d-flex justify-center headline text--secondary" v-if="searched && !results.length">{{$t('shop.noresults')}}</v-col>
                </v-row>
                <v-row v-show="results.length">
                    <v-col cols="12">
                        <v-pagination :length="pages" v-model="pagination"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="3" class="mb-0 pb-0">
                <oryl-sticky>
                    <oryl-shop-filter ref="shopfilter" v-model="filter"/>
                    <oryl-car-details :car="vehicle" v-if="vehicle"/>
                </oryl-sticky>
            </v-col>
        </v-row>
        <oryl-shop-loader @searchRequest="searchRequest" v-model="loadedCart" v-if="loadedCart"/>
        <oryl-artikel-anfrage @searchRequest="searchRequest" v-model="makeAnfrage" v-if="makeAnfrage"/>
      <oryl-to-top/>
    </oryl-container>
</template>

<script>
    import OrylShopFilter from "../../components/OrylShopFilter";
    import OrylShopResult from "../../components/OrylShopResult";
    import OrylContainer from "../../components/OrylContainer";
    import OrylToTop from "../../components/OrylToTop";
    import OrylSearchBar from "../../components/OrylSearchBar";
    import OrylCarFilter from "../../components/OrylCarFilter";
    import OrylShopCategories from "../../components/OrylShopCategories";
    import OrylSticky from "../../components/OrylSticky";
    import OrylCarDetails from "../../components/OrylCarDetails";
    import OrylShopLoader from "@/components/OrylShopLoader";
    import OrylArtikelAnfrage from "@/components/OrylArtikelAnfrage";

    export default {
        name: "Shop",
        components: {
            OrylShopLoader,
            OrylCarDetails,
            OrylSticky,
            OrylShopCategories,
            OrylCarFilter, OrylSearchBar, OrylToTop, OrylContainer, OrylShopResult, OrylShopFilter, OrylArtikelAnfrage},
        data: function () {
            return {
                search: {
                    search: '',
                    type: 'full'
                },
                loading: false,
                searched: false,
                rules: [
                    value => (value && value.length >= 4) || this.$t('shop.error.minsearch')
                ],
                pagination: 1,
                filter: {
                    attributes: []
                },
                vehicle: 0,
                category: 0,
                loadedCart: false,
                searchVal: '',
                makeAnfrage: false,
                sucheTyp: false
            }
        },
        computed: {
          sucheTypC: function() {
            if (this.sucheTyp === false) return this.$t('suche.anfang');
            return this.$t('suche.exakt');
          },
          step2: function() {
            return this.$store.state.ShopStore.Step2;
          },
            results: function () {
                let self = this;
                if (this.$store.state.ShopStore.popup.length > 0) {
                  self.loadedCart = true;
                }
              return this.resultsFiltered.slice(this.pagination * 10 - 10, this.pagination * 10);
            },
            resultsFiltered: function () {
                let self = this;
                return this.sortResults(
                    this.$store.state.ShopStore.results
                        .filter(function (item) {
                            return !self.filter.brand || item.brand.id === self.filter.brand;
                        })
                        .filter(function (item) {
                            return !self.filter.product || item.ids.genericId === self.filter.product;
                        })
                        .filter(function (item) {
                            if (Object.keys(self.filter.attributes).length === 0) {
                                return true;
                            }

                            return self.filter.attributes.every(function (filter) {
                                return item.attributes.some(function (attribute) {
                                    return attribute.name === filter.name && attribute.value === filter.value;
                                })
                            })
                        })
                    );
            },
            loadResults: function() {
                return this.results.filter(function (item) {
                    return !item.loaded;
                });
            },
            pages: function () {
                return Math.ceil(this.resultsFiltered.length / 10);
            },
            categories: function () {
                return this.$store.state.ShopStore.categories.length !== 0;
            }
        },
        methods: {
            makeRequest: function() {
              this.makeAnfrage = true;
            },
            searchRequest: function (value) {
              this.$store.commit('ShopStore/setPopup', []);
              this.search.type = value.type;
                if (value.search.length < 4) {
                    return
                }
                this.searchVal = value.search;
                this.searched = false;
                this.loading = true;
                this.pagination = 1;
                this.$refs.shopfilter.reset();
                this.$store.commit('ShopStore/setVehicles', []);
                this.$store.commit('ShopStore/setProducts', []);
                this.$store.dispatch('ShopStore/search', {search: value.search, type: value.type, brand: value.brand, exakt: this.sucheTyp!==false?'anfang':null})
                .then(() => {
                    this.loading = false;
                    this.searched = true;

                    this.$store.dispatch('ShopStore/getDetails', this.resultsFiltered);
                })
            },
            select: function (item) {
                this.search = {
                    search: item.data.no,
                    type: 'full',
                };
                this.$vuetify.goTo(0);
                this.searchRequest(this.search);
            },
            searchCategoryRequest: function () {
                if (!this.vehicle || !this.category) {
                    return;
                }

                this.searched = false;
                this.loading = true;
                this.pagination = 1;
                this.$refs.searchbar.reset();
                this.$refs.shopfilter.reset();
                this.$store.dispatch('ShopStore/searchCategory', {id: this.vehicle, category: this.category})
                    .then(() => {
                        this.loading = false;
                        this.searched = true;

                        this.$store.dispatch('ShopStore/getProducts', {id: this.vehicle, category: this.category});
                        //this.$store.dispatch('ShopStore/getDetails', this.resultsFiltered);
                    });
            },
            sortResults: function (results) {
                return [
                    ...this.sorting(results.filter(function (item) {
                        return item.primary && item.ids.webiscoId;
                    })),
                    ...this.sorting(results.filter(function (item) {
                        return !item.primary || !item.ids.webiscoId;
                    }))
                ];
            },
            sorting: function (items) {
              let searchVal = this.searchVal;
                let ret = [
                    ...items.filter(function (item) { // 100 919 0015
                      return searchVal === item.data.no;
                    }),
                    ...items.filter(function (item) {
                        return searchVal !== item.data.no && item.stocks.internal !== 0 && item.stocks.internal >= 1 && item.ids.webiscoId;
                    }),
                    ...items.filter(function (item) {
                        return searchVal !== item.data.no && item.stocks.internal === 0 && item.stocks.external >= 1 && item.ids.webiscoId;
                    }),
                    ...items.filter(function (item) {
                        return searchVal !== item.data.no && item.stocks.internal === 0 && item.stocks.external === 0 && item.ids.webiscoId;
                    }),
                    ...items.filter(function (item) {
                        return searchVal !== item.data.no && !item.ids.webiscoId;
                    })
                ]
              return ret;
            }
        },
        mounted() {
            this.$store.commit('ShopStore/setResults', []);
            this.$store.commit('ShopStore/setPopup', []);
            this.loadedCart = false;
            if (this.$store.state.ShopStore.preset) {
                this.search = this.$store.state.ShopStore.preset;
                this.searchRequest(this.search);
                this.$store.commit('ShopStore/setPreset', null);
            }
        },
        watch: {
            pagination: function () {
                if (this.results.length) {
                    this.$vuetify.goTo(0);
                    //this.$store.dispatch('ShopStore/getDetails', this.loadResults);
                }
            },
            filter: function () {
                if (this.results.length) {
                    //this.$store.dispatch('ShopStore/getDetails', this.loadResults);
                }
            },
          step2: {
              handler() {
                if (this.$store.state.ShopStore.Step2 !== false) {
                  this.$store.commit('ShopStore/setResults', []);
                  this.$store.commit('ShopStore/setPopup', []);
                  let search = this.$store.state.ShopStore.Step2;
                  this.$store.state.ShopStore.Step2 = false;
                  this.searchRequest(search);
                }
              }
            }
        }
    }
</script>

<style scoped>

</style>
