<template>
    <v-card tile class="mb-3">
        <v-toolbar color="primary" dense dark flat>
            <div>{{$t('shop.filter.car.name')}}</div>
            <v-spacer/>
            <v-btn icon @click="open = !open">
                <v-icon v-show="open">
                    mdi-minus
                </v-icon>
                <v-icon v-show="!open">
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </v-toolbar>
        <v-expand-transition>
            <v-layout d-flex column class="px-3" v-if="open">
                <form @submit.prevent="search">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field :label="$t('shop.filter.car.2')" v-model="kba1" hide-details/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :label="$t('shop.filter.car.3')" v-model="kba2" hide-details/>
                        </v-col>
                    </v-row>
                    <v-layout d-flex justify-center>
                        <v-btn color="primary" block type="submit">
                            <v-icon>
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </v-layout>
                    <v-radio-group @change="setVehicle($event)" v-if="vehicles.length">
                        <v-radio v-for="(item, key) in vehicles" :key="key" :value="item" :label="item.name"/>
                    </v-radio-group>
                </form>
                <v-divider class="mt-3"/>
                <v-autocomplete :label="$t('shop.filter.car.manufacturer')" :items="manufacturers" v-model="manufacturer" clearable @input="setManufacturer"/>
                <v-autocomplete :label="$t('shop.filter.car.model')" :items="models" v-model="model" :disabled="!models.length" clearable @input="setModel"/>
                <v-autocomplete :label="$t('shop.filter.car.type')" :items="types" v-model="type" :disabled="!types.length" clearable @input="setType" :menu-props="{'min-width': '30em'}"/>
                <v-btn color="primary" block class="mb-3" @click="resetFilter">
                    <v-icon>
                        mdi-undo-variant
                    </v-icon>
                </v-btn>
            </v-layout>
        </v-expand-transition>
    </v-card>
</template>

<script>
    export default {
        name: "OrylCarFilter",
        data: function () {
            return {
                open: true,
                kba1: '',
                kba2: ''
            }
        },
        computed: {
              vehicle: {
                get: function() {
                  return this.$store.state.ShopStore.vehicle;
                  },
                set: function (param) {
                  this.$store.state.ShopStore.vehicle = param;
                }
              },
              manufacturer: {
                get: function() {
                  return this.$store.state.ShopStore.manufacturer;
                  },
                set: function (param) {
                  this.$store.state.ShopStore.manufacturer = param;
                }
              },
              model: {
                  get: function() {
                    return this.$store.state.ShopStore.model;
                  },
                    set: function (param) {
                      this.$store.state.ShopStore.model = param;
                    }
                },
            type: {
                get: function() {
                  return this.$store.state.ShopStore.type;

                },
                  set: function (param) {
                    this.$store.state.ShopStore.type = param;
                  }
              },
            manufacturers: function () {
                return this.$store.state.ShopStore.manufacturers;
            },
            models: function () {
                return this.$store.state.ShopStore.models;
            },
            types: function () {
                return this.$store.state.ShopStore.types;
            },
            vehicles: function () {
                return this.$store.state.ShopStore.vehicles;
            }
        },
        methods: {
            search: function () {
                this.$store.dispatch('ShopStore/getVehicle', this.kba1 + this.kba2.substr(0, 3))
                .then((response) => {
                    this.manufacturer = '';
                    this.model = '';
                    this.type = '';
                    this.kba1 = '';
                    this.kba2 = '';

                    if (!response) {
                        this.$store.commit('ShopStore/setModels', []);
                        this.$store.commit('ShopStore/setTypes', []);
                        this.$store.commit('ShopStore/setCategories', []);
                        this.$emit('input', 0);
                        return;
                    }
                });
            },
            setManufacturer: function (value) {
                this.model = '';
                this.type = '';

                if (!value) {
                    this.$store.commit('ShopStore/setModels', []);
                    this.$store.commit('ShopStore/setTypes', []);
                    this.$store.commit('ShopStore/setVehicles', []);
                    this.$emit('input', 0);
                    return;
                }

                this.$store.dispatch('ShopStore/getModels', {manufacturer: value});
            },
            setModel: function (value) {
                this.type = '';

                if (!value) {
                    this.$store.commit('ShopStore/setTypes', []);
                    this.$store.commit('ShopStore/setVehicles', []);
                    this.$emit('input', 0);
                    return;
                }

                this.$store.dispatch('ShopStore/getTypes', {manufacturer: this.manufacturer, model: value});
            },
            setType: function (value) {
                this.$emit('input', value);

                if (!value) {
                    this.$store.commit('ShopStore/setVehicles', []);
                    this.$emit('input', 0);
                    return;
                }

                this.$store.dispatch('ShopStore/getCategories', value);
                this.open = false;
            },
            setVehicle: function (value) {
              this.vehicle = value;
                if (!value) {
                    this.$emit('input', 0);
                    return;
                }

                this.$emit('input', value.id);

                this.$store.dispatch('ShopStore/getModels', {manufacturer: value.manufacturer})
                .then(() => {
                    this.$store.dispatch('ShopStore/getTypes', {manufacturer: value.manufacturer, model: value.model})
                        .then(() => {
                            this.manufacturer = value.manufacturer;
                            this.model = value.model;
                            this.type = value.id;

                            this.$store.dispatch('ShopStore/getCategories', value.id)
                            this.open = false;
                        })
                })
            },
            resetFilter: function () {
                this.manufacturer = '';
                this.model = '';
                this.type = '';
                this.kba1 = '';
                this.kba2 = '';
                this.$store.commit('ShopStore/setModels', []);
                this.$store.commit('ShopStore/setTypes', []);
                this.$store.commit('ShopStore/setVehicles', []);
                this.$emit('input', 0);
            }
        },
        mounted() {
            this.$store.dispatch('ShopStore/getManufacturers');
            if (this.vehicle) {
              this.setVehicle(this.vehicle);
            }
        }
    }
</script>

<style scoped>

</style>
